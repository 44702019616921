/* eslint-disable @typescript-eslint/no-unused-vars */
import './UnderConstructionPageComponent.css';
// import coinsPalaceLogo from './../../Assets/Logo/CoinsPalaceLogo.png';
import comingSoon from './../../Assets/Logo/comingSoon.jpeg';
import { Col, Container, Image, Row } from 'react-bootstrap';

function UnderConstructionPageComponent() {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions

  const subscribeOnClickHandler = () => {
    console.log('subscribe to input');
  };

  return (
    <div className='pageUnderConstruction'>
      <div className='row'>
        {/* <div className='paragraphHeaderLarge'>Welcome To CoinsPalace</div> */}
      </div>
      <div className='breaker'></div>
      <div className='row'>
        <div className='col-12 center'>
          <img
            src={comingSoon}
            alt='Image'
            className='imageUnderConstruction'
          />
        </div>
      </div>
    </div>
  );
}

export default UnderConstructionPageComponent;
