/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect } from 'react';
import UnderConstructionPageComponent from './Components/UnderConstructionPageComponent/UnderConstructionPageComponent';

function App() {
  return (
    <div className='App data-theme'>
      <BrowserRouter>
        <Routes>
          <Route
            path='*'
            element={
              <UnderConstructionPageComponent></UnderConstructionPageComponent>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
